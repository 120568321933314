import DataFilter from "components/common/dataFilter";
import commonConstants from "constants/commonConstants";
import Constants from "constants/Constants";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import subscriptionApi from "services/apis/subscription.api";
import { ApplicationState } from "store";
import { setLeadSearchValue } from "store/leads/action";
import { setSubscriptionsFilter } from "store/subscription/action";

const SubscriptionFilter = ({ cPage, onPageChange,setIsSearchActive }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [ddnData, setDdnData] = useState<any>();
  const [showFields, setShowFields] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const dateRangeRef = useRef<HTMLDivElement | null>(null);
  const isFirstMount=useRef(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm();

  const [openSelects, setOpenSelects] = useState({
    year: false,
    monthName: false,
    OrgID: false,
    PlanID: false,
    SubscriptionTypeID: false,
    expiryIn: false,
    IsActive: false,
  })

  const handleSelectClick = (selectName) => {
    setOpenSelects(prev => ({
      ...prev,
      [selectName]: !prev[selectName]
    }));
  };




  const subscription = useSelector(
    (state: ApplicationState) => state.subscription
  );
  const allOrganizations = useSelector(
    (state: ApplicationState) => state.organization.allOrganizations
  );
  const allPlans = useSelector(
    (state: ApplicationState) => state.plan.allPlans
  );

  const loadSubscriptionFilter = () => {
    subscriptionApi.fetchFilteredSubscriptions(
      subscription.subscriptionsFilter,
      dispatch,
      commonConstants.PAGE_SIZE,
      cPage
    );
    onPageChange(1);
    setIsSearchActive(false);
    dispatch(setLeadSearchValue(""));
    
  };

  const getExpiredSubscriptions = () => {
    subscription.subscriptionsFilter.GetExpired = "expired";
    subscriptionApi.fetchFilteredSubscriptions(
      subscription.subscriptionsFilter,
      dispatch,
      commonConstants.PAGE_SIZE,
      cPage
    );
    onPageChange(1);
    setIsSearchActive(false);
    dispatch(setLeadSearchValue(""));
  };

  const reload = (clearAll = true) => {
    setSelectedDateRange("Select Date Range");
    subscription.subscriptionsFilter.BeginDate = null;
    subscription.subscriptionsFilter.EndDate = null;
    if (clearAll) {
      subscription.subscriptionsFilter.expiryIn = null;
      subscription.subscriptionsFilter.GetExpired = null;
      subscription.subscriptionsFilter.IsActive = null;
      subscription.subscriptionsFilter.PlanID = null;
      subscription.subscriptionsFilter.OrgID = null;
      subscription.subscriptionsFilter.SubscriptionTypeID = null;
      subscription.subscriptionsFilter.monthName = null;
      subscription.subscriptionsFilter.year = null;
    }
    subscriptionApi.fetchFilteredSubscriptions(
      subscription.subscriptionsFilter,
      dispatch,
      commonConstants.PAGE_SIZE,
      cPage
    );
    onPageChange(1);
    setValue("EndDate", undefined);
    // window.location.reload();
    setShowFields(false);
    setIsSearchActive(false);
    dispatch(setLeadSearchValue(""));
  };

  function OnDropDownChange(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) {
    const selectedValue = e.target.value;
    setSelectedDateRange(selectedValue);
    if (selectedValue == "Select Date Range") {
      setValue("BeginDate", null)
      setValue("EndDate", undefined);
      dispatch(
        setSubscriptionsFilter({
          ...subscription.subscriptionsFilter,
          BeginDate: null,
          EndDate: null,
        })
      );

      return;
    }

    // const selectedValue = e.target.value === "" ? null : e.target.value;
    let startDate;
    let endDate = new Date();

    if (selectedValue !== null && selectedValue.includes("Last")) {
      switch (selectedValue) {
        case "Last 30 Days":
          startDate = new Date();
          startDate.setDate(endDate.getDate() - 30);
          break;
        case "Last 60 Days":
          startDate = new Date();
          startDate.setDate(endDate.getDate() - 60);
          break;
        case "Last 90 Days":
          startDate = new Date();
          startDate.setDate(endDate.getDate() - 90);
          break;
        case "Last Week":
          startDate = new Date();
          startDate.setDate(endDate.getDate() - 7);
          break;
      }

      setValue("EndDate", endDate.toISOString().split("T")[0])

      dispatch(
        setSubscriptionsFilter({
          ...subscription.subscriptionsFilter,
          BeginDate: startDate.toISOString().split("T")[0],
          EndDate: endDate.toISOString().split("T")[0],
        })
      );
    } else {

      if (e.target.name === "EndDate") {
        setValue("EndDate", endDate.toISOString().split("T")[0])
      }

      dispatch(
        setSubscriptionsFilter({
          ...subscription.subscriptionsFilter,
          [e.target.name]: selectedValue,
        })
      );
    }
  }

  // function onSelected(e) {
  //   subscription.subscriptionsFilter.OrgID = e.value;
  // }

  const checkEndData = watch("EndDate");

  // const EndDateField = register("EndDate", {
  //   required: true,
  // });

  const selectDropdownSearch = (data) => {
    setDdnData(
      data.map((item) => {
        return { label: item.name, value: item.organizationID };
      })
    );
  };

  useEffect(() => {
    selectDropdownSearch(allOrganizations);
  }, [allOrganizations]);


  const handleClicks = () => {
    setShowFields(!showFields);
  };

  //used to close the date range popup when click outside the popup box
  useEffect(() => {
    if (subscription.subscriptionsFilter.BeginDate !== null && checkEndData === undefined) {
      return;
    } else {
      const handleClickOutside = (event: MouseEvent) => {
        if (dateRangeRef.current && !dateRangeRef.current.contains(event.target as Node)) {
          if (dateRangeRef.current) {
            setShowFields(false)
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [subscription.subscriptionsFilter.BeginDate, checkEndData]);


  //used to call the organization data when filter value is changed.
  useEffect(() => {
    if(isFirstMount.current){
      isFirstMount.current=false;
    }else{
      const timer = setTimeout(() => {
        if (selectedDateRange !== "Select Date Range" && subscription.subscriptionsFilter.BeginDate == null && subscription.subscriptionsFilter.EndDate == null || selectedDateRange !== "Select Date Range" && subscription.subscriptionsFilter.BeginDate != null && subscription.subscriptionsFilter.EndDate != null) {
          loadSubscriptionFilter()
          setShowFields(false);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [subscription.subscriptionsFilter])

  return (
    <DataFilter isLoading={subscription.isSubscriptionFiltersLoaded}>
      <form
        onSubmit={handleSubmit(loadSubscriptionFilter)}
        className="filter" >
        <div >
          <label>Start Year</label>
          <div className="select-wrapper">
            <select
              name="year"
              className="form-control"
              defaultValue={subscription.subscriptionsFilter.year as number}
              onChange={(e) => OnDropDownChange(e)}
              onClick={() => handleSelectClick('year')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, year: false }))}

            >
              <option value="">Select Year</option>
              {Constants.YEARS()
                .reverse()
                .map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
            </select>
            {openSelects.year ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>

        </div>
        <div >
          <label>Start Month</label>
          <div className="select-wrapper">
            <select
              name="monthName"
              className="form-control"
              defaultValue={
                subscription.subscriptionsFilter.monthName as string
              }
              onChange={(e) => OnDropDownChange(e)}
              onClick={() => handleSelectClick('monthName')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, monthName: false }))}
            >
              <option value="">Select Month</option>
              {Constants.MONTHS.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
            {openSelects.monthName ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>

        </div>
        <div >
          <label>Organization</label>
          {/* <Select
              className="select-container"
              placeholder="Select"
              options={ddnData}
              defaultValue={subscription.subscriptionsFilter.OrgID as number}
              onChange={(e) => {
                onSelected(e);
              }}
            /> */}
          <div className="select-wrapper">
            <select
              name="OrgID"
              className="form-control"
              defaultValue={subscription.subscriptionsFilter.OrgID as number}
              onChange={(e) => {
                OnDropDownChange(e);
              }}
              style={{ maxWidth: "200px" }}
              onClick={() => handleSelectClick('OrgID')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, OrgID: false }))}
            >
              <option value="">Select Organization</option>
              {ddnData &&
                ddnData.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                ))}
            </select>
            {openSelects.OrgID ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>

        </div>
        <div >
          <label>Plan</label>
          <div className="select-wrapper">
            <select
              name="PlanID"
              className="form-control"
              defaultValue={subscription.subscriptionsFilter.PlanID as number}
              onChange={(e) => OnDropDownChange(e)}
              onClick={() => handleSelectClick('PlanID')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, PlanID: false }))}
            >
              <option value="">Select Plan </option>
              {allPlans.map((item, index) => (
                <option value={item["id"]} key={index}>
                  {item["name"]}
                </option>
              ))}
            </select>
            {openSelects.PlanID ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>

        </div>
        <div >
          <label>Subscription Type</label>
          <div className="select-wrapper">
            <select
              name="SubscriptionTypeID"
              defaultValue={
                subscription.subscriptionsFilter.SubscriptionTypeID as number
              }
              onChange={(e) => OnDropDownChange(e)}
              className="form-control"
              onClick={() => handleSelectClick('SubscriptionTypeID')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, SubscriptionTypeID: false }))}
            >
              <option value="">Select Subscription Type</option>
              {subscription.subscriptionTypes.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.type}
                </option>
              ))}
            </select>
            {openSelects.SubscriptionTypeID ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>

        </div>
        <div >
          <label>Expired In</label>
          <div className="select-wrapper">
            <select
              name="expiryIn"
              className="form-control"
              defaultValue={subscription.subscriptionsFilter.expiryIn as string}
              onChange={(e) => OnDropDownChange(e)}
              onClick={() => handleSelectClick('expiryIn')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, expiryIn: false }))}
            >
              <option value="">Select ExpiryIn</option>
              {Constants.EXPIRY_IN.map((item, index) => (
                <option value={item.value} key={index}>
                  {item.label}
                </option>
              ))}
            </select>
            {openSelects.expiryIn ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>

        </div>
        <div >
          <label>Status</label>
          <div className="select-wrapper">
            <select
              name="IsActive"
              onChange={(e) => OnDropDownChange(e)}
              className="form-control"
              defaultValue={""}
              onClick={() => handleSelectClick('IsActive')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, IsActive: false }))}
            >
              <option value="">Select Status</option>
              <option value="true">Active</option>
              <option value="false">InActive</option>
            </select>
            {openSelects.IsActive ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>

        </div>


        <div className="relative">
          <label>Date Range</label>
          <div className="date-range">
            <input
              readOnly
              className="form-control"
              placeholder={`${subscription.subscriptionsFilter.BeginDate ? subscription.subscriptionsFilter.BeginDate : "Select a Date Range"} ${subscription.subscriptionsFilter.EndDate ? "-" : ""} ${subscription.subscriptionsFilter.EndDate ? subscription.subscriptionsFilter.EndDate : ""}`}
            ></input>
            {
              showFields ? <RiArrowDropUpLine onClick={handleClicks} /> : <RiArrowDropDownLine onClick={handleClicks} />
            }
          </div>

          {showFields && (
            <div ref={dateRangeRef} className={`dateRangeRef ${showFields ? 'show' : 'hide'}`} >
              <div className="flex" style={{ gap: "0.5rem", alignItems: "stretch", flexWrap: "wrap" }}>
                <fieldset>
                  <div className="tmf-form-group">
                    <label style={{ color: "black" }}>Date Range</label>
                    <select
                      className="form-control"
                      onChange={(e) => OnDropDownChange(e)}
                      value={selectedDateRange}
                    >
                      <option value="Select Date Range">Select Date Range</option>
                      <option value="Last 30 Days">Last 30 Days</option>
                      <option value="Last 60 Days">Last 60 Days</option>
                      <option value="Last 90 Days">Last 90 Days</option>
                      <option value="Last Week">Last Week</option>
                    </select>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="tmf-form-group">
                    <label>Start Date</label>
                    <input
                      placeholder="date"
                      className="form-control sm"
                      type="date"
                      name="BeginDate"
                      value={`${subscription.subscriptionsFilter.BeginDate}`}
                      onChange={(e) => OnDropDownChange(e)}
                    />
                  </div>
                </fieldset>

                <fieldset>
                  {subscription.subscriptionsFilter.BeginDate ? (
                    <div className="tmf-form-group">
                      <label>End Date</label>

                      <input
                        placeholder="date"
                        className="form-control"
                        type="date"
                        value={`${subscription.subscriptionsFilter.EndDate}`}
                        name="EndDate"
                        // {...EndDateField}
                        onChange={(e) => {
                          // EndDateField.onChange(e);
                          OnDropDownChange(e);
                        }}
                        min={
                          subscription.subscriptionsFilter.BeginDate as string
                        }
                      />
                      <small className="text-danger">
                        {/* {errors.EndDate?.type === "required" &&
                            "End Date is required"} */}
                        {checkEndData === undefined ? "End Date is required" : ""}
                      </small>
                    </div>
                  ) : (
                    <div className="tmf-form-group">
                      <label>End Date</label>
                      <input disabled className="form-control" type="date"
                        value={`${subscription.subscriptionsFilter.EndDate}`} />
                    </div>
                  )}
                </fieldset>
              </div>



              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "12px",
                  gap: "20px",
                }}>

                {/* <div className="tmf-filter__submit">
                  <button
                    className="apply"
                    type="submit"
                    onClick={() => {
                      loadSubscriptionFilter();
                      setShowFields(!showFields);
                    }}
                  >
                    Apply
                  </button>
                </div> */}

                <div className="">
                  <button
                    className="clear"
                    type="reset"
                    onClick={() => reload(false)}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div style={{ marginTop: "25px" }}>
          <button
            onClick={() => {
              loadSubscriptionFilter();
              // setShowFields(!showFields);
            }}
            className="apply"
            type="submit"
          >
            Apply
          </button>
        </div>
        <div style={{ marginTop: "25px" }}>
          <button
            onClick={() => getExpiredSubscriptions()}
            className="apply"
            type="submit"
          >
            Expired
          </button>
        </div>
        <div style={{ marginTop: "25px" }}>
          <button onClick={() => reload()} className="clear" type="reset">
            Clear
          </button>
        </div>
      </form>
    </DataFilter>
  );
};

export default SubscriptionFilter;

import {
  CDataTable,
  CSwitch
} from "@coreui/react";
import axios from "axios";
import { DTablePagination } from "components/datatable/pagination";
import TMFSearch from "components/input/TMFSearch";
import AddUserModal from "components/modals/user/AddUserModal";
import DeleteUserModal from "components/modals/user/DeleteUserModal";
import EditUserModal from "components/modals/user/EditUserModal";
import UndoDeleteUserModal from "components/modals/user/UndoDeleteUserModal";
import ViewUserDetails from "components/modals/user/ViewUserDetails";
import commonConstants from "constants/commonConstants";
import { TotalCountContext } from "context/TotalCount";
import { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import api from "services/api.service";
import userApi from "services/apis/user.api";
import { ApplicationState } from "store";
import { setLeadSearchValue } from "store/leads/action";
import {
  setDeleteUserModal,
  setEditUserModal,
  setUndoDeleteUserModal,
  setUsers,
  setViewUserModal,
} from "store/user/action";
import Constants from "../../constants/Constants";
import UserActions from "./userActions";
import UserFilter from "./userFIlter";
import UserStatusModal from "./UserStatusModal";
import UserTableVariables from "./userTableVariables";

const UserActive = () => {
  const totalData = useContext(TotalCountContext);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const dispatch: Dispatch<any> = useDispatch();
  const user = useSelector((state: ApplicationState) => state.user);
  const [AddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [userStatusModal, setUserStatusModal] = useState(false);
  const [userID, setUserID] = useState(Number);
  const [allUsers, setAllUsers] = useState("");
  const [userEvent, setUserEvent] = useState(Boolean);
  const [pageSize, setPageSize] = useState(commonConstants.PAGE_SIZE);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const isFirstMount = useRef(true);

  const [request, setRequest] = useState({
    BeginDate: null,
    EndDate: null,
    IsActive: null,
    OrganizationID: null,
  });

  const searchValue = useSelector(
    (state: ApplicationState) => state.lead.leadSearchValue
  );

  const [userCount, setUserCount] = useState();

  const initialLoadData = () => {
    setIsSearchActive(false)
    dispatch(setLeadSearchValue(""));
    userApi.filterUsers(usersFilter, dispatch, pageSize, pageNo);
  }

  const loadData = () => {
    if (searchValue) {
      axios
        .get(`${Constants.USER_SEARCH_URI}/${searchValue}`)
        .then((res) => {
          dispatch(setUsers(res.data));
        })
    } else {
      setIsSearchActive(false)
      dispatch(setLeadSearchValue(""));
      userApi.filterUsers(usersFilter, dispatch, pageSize, pageNo);
    }
  };

  const cancelData = () => {
    setIsSearchActive(false)
      dispatch(setLeadSearchValue(""));
      userApi.filterUsers(usersFilter, dispatch, pageSize, pageNo);
  };

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
      initialLoadData();
      getAllUserList();
    } else {
      loadData();
    }
  }, [pageNo]);

  //load All user By DateRange
  const loadDateRange = async () => {
    await api.post(Constants.DATE_WISE_USER_FILTER_URI, request).then((res) => {
      // window.alert(`You are viewing User Report!`);
    });
  };
  
  const usersFilter = useSelector(
    (state: ApplicationState) => state.user.usersFilter
  );

  useEffect(() => {
    getAllUserDataCount();
  }, []);

  const getAllUserDataCount = () => {
    axios.post(Constants.GET_USER_COUNT, usersFilter).then((res) => {
      setTotalPage(Math.ceil(Math.abs(res.data / commonConstants.PAGE_SIZE)));
      setUserCount(res.data);
    });
  };

  const getAllUserList = () => {
    axios
      .post(Constants.USER_ALLORGANIZATIONS_URI, usersFilter)
      .then((res) => {
        setAllUsers(res.data);
      })
      .catch((err) => { });
  };

  const handleUerStatus = (item: IUser) => {
    setUserID(item.userID);
    setUserEvent(item.isActive);
    setUserStatusModal(true);
  };

  // const handleUserStatusModal = (item: IUser) => {
  //   let confirmation = window.confirm(
  //     `Do you want to update "${
  //       item.first_Name + " " + item.last_Name + "'s"
  //     }" status?`
  //   );
  //   if (confirmation) {
  //     userApi.toggleUserStatus(item, dispatch);
  //   } else {
  //     dispatch(setUserStatus({ ...item }));
  //   }
  // };

  const editUserModal = useSelector(
    (state: ApplicationState) => state.user?.editUserModal
  );
  const deleteUserModal = useSelector(
    (state: ApplicationState) => state.user?.deleteUserModal
  );
  const viewUserModal = useSelector(
    (state: ApplicationState) => state.user?.viewUserModal
  );
  const undoDeleteUserModal = useSelector(
    (state: ApplicationState) => state.user?.undoDeleteUserModal
  );


  const getUserType = (userTypeID: number) => {
    switch (userTypeID) {
      case 1:
        return "ADMIN";
      case 2:
        return "USER";
      case 3:
        return "Dummy";
      case 4:
        return "STUDENT";
      case 5:
        return "TEACHER";
      case 6:
      default:
        return "...";
    }
  };
  const csvLinkRefCurrentPage = useRef<
    HTMLAnchorElement & CSVLink & { link: HTMLAnchorElement }
  >(null);


  const UserPageTitleBuild = () => {
    return (
      <>
        <div className="title">
          <div className="page-title">Users</div>
          <div>
            <button
              className="create"
              onClick={() => setAddUserModalOpen(true)}
            >
              Add New
            </button>
          </div>
        </div>
      </>
    );
  };

  const handleCSVClick = () => {
    csvLinkRefCurrentPage.current?.link.click();
  };

  const UserTableBuild = () => {
    return (
      <div className="tmf-table_wrapper">
        <div
          className={`tmf-datatable__wrapper tmf_hide_build_in_pagination ${isSearchActive && "tmf_paggination_state"
            }`}
          style={{
            width: "100%",
            maxWidth: "100%",
            overflowX: "auto"
          }}
        >
          <div style={{
            borderTopColor: "#fff",
            width: "1200px",
            minWidth: "100%",
            overflowX: "auto",
          }}>
            <div className="table-filter-section">
              <TMFSearch
                sapi={Constants.USER_SEARCH_URI}
                dispatch={dispatch}
                isSearchActive={(e) => setIsSearchActive(e)}
                searchIn="users"
                onCalcelSearch={() => cancelData()}
              />


              <div
                style={{ display: "flex", gap: "30px", alignItems: "center" }}
              >


                <div style={{ position: "relative" }}>
                  <div className="d-flex" >
                    <button
                      className="exportcsv"
                    >
                      Export To CSV
                    </button>
                    <button
                      className="open-button"
                      onClick={() => setIsOpen((prev) => !prev)}
                    >
                      {
                        isOpen ? <RiArrowDropUpLine style={{ width: '25px', height: "25px" }} /> : <RiArrowDropDownLine style={{ width: '25px', height: "25px" }} />
                      }
                    </button>

                  </div>

                  {
                    isOpen && (
                      <div className={`dropdownmenubox ${isOpen ? "show" : "hide"}`}   >
                        <button style={{ color: "var(--txt-primary)",background:"transparent",outline:"none",border:"none" }} onClick={() => setIsOpen((prev) => !prev)}>

                          <CSVLink
                            headers={UserTableVariables.CSVHeaders}
                            data={allUsers}
                            filename="AllUsers.csv"
                            ref={csvLinkRef}

                            style={{ color: "var(--txt-primary)" }}

                          >
                            Export All
                          </CSVLink>
                        </button>

                        <div>
                          <button
                            onClick={() => { handleCSVClick(); setIsOpen((prev) => !prev); }}
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              color: "var(--txt-primary)",
                              outline: "none",
                              cursor: "pointer"
                            }}
                          >
                            Export Current Page Data
                          </button>
                          <CSVLink
                            headers={UserTableVariables.CSVHeaders}
                            data={user.users}
                            filename="CurrentPageUserList.csv"
                            ref={csvLinkRefCurrentPage}
                          />
                        </div>

                      </div>
                    )
                  }
                </div>


                {/* 
              <CDropdown className="d-flex ">
                <button
                  onClick={handleCSVClick}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid var(--primary, #1C63B7)",
                    borderRadius: "25px 0px 0px 25px",
                    background: "#fff",
                    width: "150px",
                    height: "40px",
                    outline: "none",
                  }}
                >
                  Export TO CSV
                </button>
                <CSVLink
                  headers={UserTableVariables.CSVHeaders}
                  data={user.users}
                  filename="CurrentPageUserList.csv"
                  ref={csvLinkRef}
                  className="hidden"
                />
                <CDropdownToggle
                  caret
                  style={{
                    borderRadius: "0px 25px 25px 0px",
                    borderTop: " 1px solid var(--primary, #1C63B7)",
                    borderRight: "1px solid var(--primary, #1C63B7)",
                    borderBottom: "1px solid var(--primary, #1C63B7)",
                    height: "40px",
                    marginLeft: "0 !important",
                  }}
                // className="ml-1 csv-toggle"
                >
                  <CDropdownMenu className="mt-1">
                    <div className="m-1">
                      <MdDoNotDisturbOnTotalSilence />
                      <CSVLink
                        headers={UserTableVariables.CSVHeaders}
                        data={allUsers}
                        filename="AllUsers.csv"
                        ref={csvLinkRef}
                        className="ml-1"
                        style={{
                          textDecoration: "none",
                          color: "#5d6c77",
                          fontSize: "12px",
                          lineHeight: "18px",
                        }}
                      >
                        Export All
                      </CSVLink>
                    </div>
                  </CDropdownMenu>
                </CDropdownToggle>
              </CDropdown> */}

                {
                  !isSearchActive && <DTablePagination
                    cPage={pageNo}
                    tPage={totalPage!}
                    onPageChange={(e) => {
                      setPageNo(e);
                      loadDateRange();
                    }}
                  ></DTablePagination>
                }

              </div>


            </div>


            <CDataTable
              items={user.users}
              fields={UserTableVariables.fields}
              hover
              loading={user.isUsersLoading}
              scopedSlots={{
                name: (item: IUser) => (
                  <td  >
                    <div className="name">
                      {
                        item.isDeleted ? <span
                          style={{
                            fontSize: "1.25rem",
                            fontWeight: "500",
                            color: "#524467",
                            textDecoration: "line-through"
                          }}
                        >
                          {`${item.first_Name} ${item.last_Name}`}
                        </span> :
                          <span
                            style={{
                              fontSize: "1.25rem",
                              fontWeight: "500",
                              color: "var(--txt-primary, #23005B)",
                            }}
                          >
                            {`${item.first_Name} ${item.last_Name}`}
                          </span>
                      }

                      <span style={{
                        display: "flex",
                        gap: "0.8rem",
                        fontSize: "0.875rem",
                        fontWeight: "400"

                      }}>
                        <span>{getUserType(item.userTypeID)}</span>
                        <span>Created:{item.creationDate}</span>
                      </span>
                    </div>
                  </td>
                ),
                detail: (item: IUser) => (
                  <td>
                    <div className="detail">
                      <span
                        style={{
                          color: "var(--txt-primary, #23005B)",
                        }}
                      >
                        {item.email ? item.email : "..."}
                      </span>

                      <span style={{ paddingTop: "6px" }}>{item.phone}</span>
                    </div>

                  </td>
                ),

                record: (item: IUser) => (
                  <td style={{ padding: "15px" }}>
                    <div className="record">
                      <div className="check-box ">

                        <div className="checkbox-item">
                          {item.isDeleted ? (
                            <CSwitch
                              disabled
                              color="success"
                              defaultChecked={item.isActive}
                              labelOn="On"
                              labelOff="Off"
                              shape="pill"
                              variant="opposite"
                              size="sm"
                            />
                          ) : (
                            <CSwitch
                              color="success"
                              defaultChecked={item.isActive}
                              labelOn="On"
                              labelOff="Off"
                              shape="pill"
                              variant="opposite"
                              size="sm"
                              onChange={(e) => handleUerStatus(item)}
                              id={"planSwitch" + item.isActive}
                            />
                          )}
                          <span>Active</span>

                        </div>


                      </div>
                    </div>
                  </td>
                ),
                Action: (item: IUser) => (
                  <td>
                    <UserActions user={item}></UserActions>
                  </td>
                ),
              }}
            />

            {!isSearchActive && (
              <DTablePagination
                tPage={totalPage!}
                cPage={pageNo}
                onPageChange={(e) => setPageNo(e)}
              />
            )}


          </div>
        </div>
      </div>

    );
  };

  const UserActionModels = () => {
    return (
      <>
        {AddUserModalOpen && (
          <AddUserModal
            isOpen={AddUserModalOpen}
            onClose={() => setAddUserModalOpen(false)}
            refreshDetails={() => loadData()}
          />
        )}
        {viewUserModal?.showModal && (
          <ViewUserDetails
            isOpen={viewUserModal?.showModal}
            onClose={() => dispatch(setViewUserModal({ showModal: false }))}
            refreshDetails={() => loadData()}
            userDetails={viewUserModal?.user}
          />
        )}
        {editUserModal?.showModal && (
          <EditUserModal
            isOpen={editUserModal?.showModal}
            onClose={() => dispatch(setEditUserModal({ showModal: false }))}
            refreshDetails={() => loadData()}
            id={editUserModal?.user?.userID}
          />
        )}
        {deleteUserModal?.showModal && (
          <DeleteUserModal
            isOpen={deleteUserModal?.showModal}
            onClose={() => dispatch(setDeleteUserModal({ showModal: false }))}
            refreshDetails={() => loadData()}
            userId={deleteUserModal?.user?.userID}
          />
        )}
        {userStatusModal && (
          <UserStatusModal
            isOpen={userStatusModal}
            onClose={() => setUserStatusModal(false)}
            refreshDetails={() => loadData()}
            userID={userID}
            event={userEvent}
          />
        )}
        {undoDeleteUserModal?.showModal && (
          <UndoDeleteUserModal
            isOpen={undoDeleteUserModal?.showModal}
            onClose={() =>
              dispatch(setUndoDeleteUserModal({ showModal: false }))
            }
            refreshDetails={() => loadData()}
            userDetails={undoDeleteUserModal?.user}
          />
        )}
      </>
    );
  };

  return (
    <div>
      <UserPageTitleBuild></UserPageTitleBuild>
      <UserFilter
        cPage={pageNo}
        onPageChange={(e) => {
          setPageNo(e);
        }}
        setIsSearchActive={setIsSearchActive}
      ></UserFilter>


      <UserTableBuild></UserTableBuild>
      <UserActionModels></UserActionModels>
    </div>
  );
};

export default UserActive;
